import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

const FAQ = () => (
    <Layout>
        <SEO title="FAQ" /> 

        <Navbar />

        <PageBanner pageTitle="FAQ" />

        <div className="faq-area ptb-80">
            <div className="container">
                <div className="faq-accordion">
                    <Accordion allowZeroExpanded preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        How do permissions work in Google Play Instant?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Is Smart Lock required for instant apps?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Can I have multiple activities in a single feature?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    
                        <AccordionItem uuid="d">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Can I share resources between features?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="e">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Is multidex supported for instant apps?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="f">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Can I share resources between features?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>

                <div className="faq-contact">
                    <h3>Ask Your Question</h3>
                    <form>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input type="text" placeholder="Name" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input type="email" placeholder="Email" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <input type="text" placeholder="Subject" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <textarea name="message" cols="30" rows="6" placeholder="Message" className="form-control"></textarea>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <button className="btn btn-primary" type="submit">Submit Now!</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default FAQ;